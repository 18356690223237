import React, { useState } from "react";
import axios from "axios";
import "./ContactFormComponent.css";
import logo from "../assets/images/logo1000gif.gif";

const ContactFormComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Truncate input values
    const truncatedFormData = {
      name: formData.name.substring(0, 50),
      email: formData.email.substring(0, 100),
      message: formData.message.substring(0, 500),
    };

    try {
      const response = await axios.post(
        "https://noc-api-dev.azurewebsites.net/contact",
        truncatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log("Form data submitted successfully");
        setFormSubmitted(true);
        setSubmitError(false);
      } else {
        console.error("Failed to submit form data");
        setSubmitError(true);
      }
    } catch (error) {
      console.error("An error occurred while submitting form data:", error);
      setSubmitError(true);
    }
  };

  return (
    <section className="contact-form-container">
      <img src={logo} className="app-logo min-logo" alt="logo" />
      {!formSubmitted ? (
        <div className="card">
          <h2>Contattaci</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nome:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                maxLength={50}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                maxLength={100}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Messaggio:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                maxLength={500}
              ></textarea>
            </div>
            <div className="form-button">
              {!submitError && <button type="submit">Invia</button>}
              {submitError && (
                <p className="error-message">
                  Al momento non siamo in grado di ricevere richieste di
                  contatto, riprova più tardi
                </p>
              )}
              <button
                type="submit"
                style={{ marginTop: 10 }}
                onClick={() => (window.location.href = "/")}
              >
                Torna alla Home
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="success-message">
          <h2>Grazie per averci contattato!</h2>
          <button onClick={() => (window.location.href = "/")}>
            Torna alla Home
          </button>
        </div>
      )}
    </section>
  );
};

export default ContactFormComponent;
