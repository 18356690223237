import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComponent from './components/HomeComponent';
import ContactFormComponent from './components/ContactFormComponent';
import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ActivateComponent from './components/ActivateComponent';
import LoginComponent from './components/LoginComponent';

export default function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyDnEBQCRE1VjoF_2By1kOPwLMUIaOLMK8M",
    authDomain: "noc-app-development.firebaseapp.com",
    projectId: "noc-app-development",
    storageBucket: "noc-app-development.appspot.com",
    messagingSenderId: "662871468997",
    appId: "1:662871468997:web:90c23ac59ec62f1a22b9ac",
    measurementId: "G-2P6QZG7PT5",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/contact" element={<ContactFormComponent />} />
        <Route path="/activate" element={<ActivateComponent />} />
        <Route path="/login" element={<LoginComponent />} />
      </Routes>
    </Router>
  );
}
