import React from "react";
import apple from "../assets/images/apple.png";
import play from "../assets/images/play.png";
import ig from "../assets/images/ig.png";
import "./ReasonsComponent.css";

export default function ReasonsComponent() {
  const openAppStore = () => {
    const link = "https://apps.apple.com/it/app/not-only-cars/id6496851843";
    window.location.href = link;
  };
  return (
    <>
      <section className="app-section container">
        <h3 className="text-left">Perché sceglierci se sei un'attività?</h3>
        <ol>
          <li>
            <p>
              Se sei un noleggiatore di veicoli speciali avrai accesso a un
              numero di clienti illimitato con l'aiuto di strumenti per gestire
              la tua intera attività.
            </p>
          </li>
          <li>
            <p>
              Se sei un organizzatore di eventi non avrai più problemi nel
              pubblicizzare il tuo evento ne tanto meno a gestire le
              partecipazioni.
            </p>
          </li>
          <li>
            <p>
              Se hai un'officina o un servizio di detailing avrai sempre auto
              prestigiose su cui lavorare.
            </p>
          </li>
          <li>
            <p>
              Se vendi prodotti potrai espandere il tuo business in tutta Italia
              con una semplicità mai vista finora.
            </p>
          </li>
        </ol>
      </section>
      <section className="app-section container">
        <h3 className="text-left">
          Perché unirti a noi se sei un appassionato?
        </h3>
        <ol>
          <li>
            <p>
              Noleggia illimitati veicoli al prezzo più competitivo sul mercato
              nel modo più semplice e agevole possibile.
            </p>
          </li>
          <li>
            <p>
              Dirai addio alle giornate in cui non saprai a quale evento andare.
            </p>
          </li>
          <li>
            <p>
              Potrai entrare in contatto con i professionisti del settore che
              tratteranno la tua auto come se fosse la loro.
            </p>
          </li>
          <li>
            <p>
              Avrai a portata di click esperienze e prodotti per te, per la tua
              auto e per la tua passione H24.
            </p>
          </li>
        </ol>
      </section>
      <section
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2>Ti abbiamo convinto?</h2>
        <a href="https://www.instagram.com/notonlycars.srl/" className="button">
          <div className="image-container">
            <img src={ig} alt="logo" />
            <span>Seguici per rimanere aggiornato</span>
          </div>
        </a>
        <button onClick={openAppStore}>
          <div className="image-container">
            <img src={apple} alt="logo" />
            <span>Disponibile su Apple Store</span>
          </div>
        </button>
        <button>
          <div className="image-container">
            <img src={play} alt="logo" />
            <span>Presto disponibile su Google Play Store</span>
          </div>
        </button>
      </section>
    </>
  );
}
