import React from "react";
import logo from "../assets/images/logo1000gif.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./HeaderComponent.css";

export default function HeaderComponent() {
  return (
    <header className="app-header">
      <img src={logo} className="app-logo" alt="logo" />
      <FontAwesomeIcon
        icon={faArrowDown}
        color="black"
        size="2x"
        className="arrow-icon"
      />
    </header>
  );
}
