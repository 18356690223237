import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./ActivateComponent.css";
import logo from "../assets/images/logo1000gif.gif";

const LoginComponent = () => {
  const [activationCode, setActivationCode] = useState("");
  const [activationStatus, setActivationStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Hook per la navigazione

  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("activationCode") ?? "";
    setActivationCode(code);

    if (activationCode) {
      axios
        .post(
          `https://noc-api-dev.azurewebsites.net/user/activate?guid=${activationCode}`
        )
        .then((response) => {
          if (response.status === 200) {
            setActivationStatus("success");
            try {
              window.location.href = `nocapp://token=${activationCode}`;
            } catch (err) {
              console.log(err);
            }
          } else {
            setActivationStatus("error");
          }
        })
        .catch(() => {
          setActivationStatus("error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setActivationStatus("error");
      setLoading(false);
    }
  }, [activationCode]);

  const handleGoHome = () => {
    navigate("/"); // Naviga alla home page
  };

  return (
    <div className="activate-container">
      <div className="logo-container">
        <img src={logo} className="app-logo" alt="Logo" />
      </div>
      <h1 className="activation-title">Login</h1>

      {loading ? (
        <p className="loading-text">Verifica del codice in corso...</p>
      ) : activationStatus === "success" ? (
        <div className="success-activation-div">
          <p className="activation-text success-text">
            Accetta di aprire l'app!
          </p>
          <button className="home-button" onClick={handleGoHome}>
            Torna alla Home
          </button>
        </div>
      ) : (
        <div className="unsuccess-activation-div">
          <p className="activation-text error-text">
            Link non valido o scaduto.
          </p>
          <button className="home-button" onClick={handleGoHome}>
            Torna alla Home
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginComponent;
